import { Drawer, Typography } from "antd"
import { FC, Fragment } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { HeaderMenu } from "src/components/menu"
import { useBreakpoints } from "../../../hooks/useBreakpoints"
import { useScrollDirection } from "../../../hooks/useScrollDirection"
import { useApp } from "../../app"
import { SvgIcon } from "../../icon"
import { Container, CustomButton } from "../../ui"
import { TranslateElement } from "./translation"

import "./index.less"

interface HeaderProps {
  logo: Maybe<FileFragment>
  headerMenu: Maybe<Array<Maybe<ComponentUiLinksGroup>>>
  button: Maybe<ComponentUiButton>
  loginButton: Maybe<ComponentUiButton>
}

const Header: FC<HeaderProps> = ({ logo, button, headerMenu, loginButton }) => {
  const { isTablet } = useBreakpoints()

  const navigation = useNavigate()

  const { burger } = useApp()

  const date = new Date().getFullYear()

  const { scrollDirection } = useScrollDirection()

  return (
    <Container className={"header-content"}>
      {logo && (
        <img
          style={{ cursor: "pointer" }}
          src={logo.attributes?.url}
          alt={"logo"}
          onClick={() => navigation("/pages/home")}
        />
      )}
      {isTablet && (
        <Fragment>
          <button className={"burger-btn"} onClick={burger.toggle}>
            <SvgIcon type={"hamburger"} />
          </button>
          <Drawer
            contentWrapperStyle={{ height: "100%" }}
            rootClassName={`drawer-burger ${burger.opened ? "open" : ""}`}
            width={"100%"}
            placement={"top"}
            open={burger.opened}
            closeIcon={false}
          >
            {
              <div className={"drawer-close"} onClick={burger.toggle}>
                Close
              </div>
            }
            <div className={"drawer-burger-middle"}>
              {headerMenu && <HeaderMenu data={headerMenu} />}
              {loginButton?.url && (
                <NavLink to={loginButton.url}>
                  <CustomButton className={"log-in-btn"} title={loginButton.title ?? ""} />
                </NavLink>
              )}
              {button?.url && <TranslateElement {...button} />}
            </div>
            {logo && (
              <div className={"drawer-footer"}>
                <img src={logo.attributes?.url} alt={"logo"} />
                <Typography.Text>{`© ${date} noxe`}</Typography.Text>
              </div>
            )}
          </Drawer>
        </Fragment>
      )}
      {!isTablet && headerMenu && <HeaderMenu data={headerMenu} />}
      {!isTablet && (
        <div className={"header-buttons"}>
          {!isTablet && loginButton?.url && (
            <NavLink to={loginButton.url}>
              <CustomButton className={"log-in-btn"} title={loginButton.title ?? ""} />
            </NavLink>
          )}
          {!isTablet && button?.url && <TranslateElement {...button} />}
        </div>
      )}
    </Container>
  )
}

export { Header }
