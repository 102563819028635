import { FC } from "react"

import "./index.less"

type CustomButtonTypes = {
  title: string
  className: string
  onClick?: () => void
}

const CustomButton: FC<CustomButtonTypes> = ({ className, title, onClick }) => {
  return (
    <button className={`button ${className}`} onClick={onClick}>
      {title}
    </button>
  )
}

export { CustomButton }
