import { Layout as BaseLayout } from "antd"
import { FC, Suspense } from "react"
import { Outlet, ScrollRestoration } from "react-router-dom"
import { Loader } from ".."
import { useLayoutQuery } from "../../../graphql"
import { useApp } from "../../app"
import { Footer } from "../footer"
import { Header } from "../header"

import "./index.less"

interface LayoutProps {}

const Layout: FC<LayoutProps> = () => {
  const { lang } = useApp()
  const { data, loading } = useLayoutQuery({
    variables: {
      locale: lang.language,
    },
  })

  if (loading) {
    return <Loader />
  }

  const headerAttributes = data?.layout?.data?.attributes

  return (
    <BaseLayout>
      <ScrollRestoration />
      <BaseLayout.Header>
        <Header
          button={headerAttributes?.headerButton}
          logo={headerAttributes?.headerLogo?.data}
          headerMenu={headerAttributes?.headerMenu}
          loginButton={headerAttributes?.loginButton}
        />
      </BaseLayout.Header>
      <BaseLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </BaseLayout>
      <BaseLayout.Footer>
        <Footer
          links={headerAttributes?.footerNavigation}
          logo={headerAttributes?.footerLogo?.data}
          contact={headerAttributes?.footerContact}
        />
      </BaseLayout.Footer>
    </BaseLayout>
  )
}

export { Layout }
