import { FC } from "react"
import { NavLink } from "react-router-dom"
import { useBreakpoints } from "../../../../hooks/useBreakpoints"
import { useApp } from "../../../app"
import { SvgIcon } from "../../../icon"
import { CustomButton } from "../../../ui"

import "./index.less"

interface LanguageProps {
  langCode: string
  label: string
}

const LanguageOption: FC<LanguageProps> = ({ langCode, label }) => {
  const { lang } = useApp()

  const handleChangeLanguage = () => {
    lang.setLanguage(langCode)
    localStorage.setItem("strapi-admin-language", langCode)
  }

  return (
    <p data-lang={langCode} className={lang.language === langCode ? "active" : ""} onClick={handleChangeLanguage}>
      {label}
    </p>
  )
}

const TranslateElement: FC<Partial<ComponentUiButton>> = ({ title, url }) => {
  const { isTablet } = useBreakpoints()

  return (
    <div className={"header-translate-wrapper"}>
      {url && (
        <NavLink to={url}>
          <CustomButton className={"gray"} title={title ?? ""} />
        </NavLink>
      )}
      <div className={"header-translate"}>
        {!isTablet && <SvgIcon type={"lang"} />}

        <LanguageOption langCode={"en"} label={"EN"} />

        {!isTablet ? <p>\</p> : <p>|</p>}

        <LanguageOption langCode={"fr"} label={"FR"} />
      </div>

    </div>
  )
}

export { TranslateElement }
